<template>
    <div style="display:flex;justify-content:center;">
        <div style="width:1520px;margin-top:20px">
            <div class="text-left">
                <el-button type="default" @click="toBack">返回</el-button>
            </div>
            <div class="header-title">
                企业认证
            </div>
            <div style="margin-top:40px">
                <el-steps :active="stepActive" align-center>
                    <el-step>
                        <div slot="title" class="step-title">
                            资料填写
                        </div>
                        <div slot="icon" class="step-icon" :style="{ background: stepActive >= 1 ? '#D2E6FC' : '#DFDFDF' }">
                            <div :style="{ background: stepActive >= 1 ? '#2180EF' : '#888888' }">1</div>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="title" class="step-title">
                            资料审核
                        </div>
                        <div slot="icon" class="step-icon" :style="{ background: stepActive >= 2 ? '#D2E6FC' : '#DFDFDF' }">
                            <div :style="{ background: stepActive >= 2 ? '#2180EF' : '#888888' }">2</div>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="title" class="step-title">
                            认证结果
                        </div>
                        <div slot="icon" class="step-icon" :style="{ background: stepActive >= 3 ? '#D2E6FC' : '#DFDFDF' }">
                            <div :style="{ background: stepActive >= 3 ? '#2180EF' : '#888888' }">3</div>
                        </div>
                    </el-step>
                </el-steps>
            </div>


            <template v-if="stepActive === 1 && pageType !== 'detail'">
                <div class="text-left" style="margin-top:40px">
                    <el-form :model="formData" ref="form" label-width="160px" label-position="right" :rules="rules"
                        :validate-on-rule-change="false">
                        <div class="title-bar text-left">填写企业信息</div>
                        <el-row>
                            <el-col :span="12" v-if="pageType === 'insert'">
                                <el-form-item prop="name" label="企业名称">
                                    <el-autocomplete v-model="formData.name" :fetch-suggestions="searchCompany"
                                        @change="compNameChange" placeholder="请输入企业名称" class="width360"
                                        @select="toSelectCompany"></el-autocomplete>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-else>
                                <el-form-item prop="name" label="企业名称">
                                    <el-input v-model="formData.name" placeholder="请输入企业名称" class="width360"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="tags" label="企业标签">
                                    <el-cascader clearable v-model="formData.tags" :options="tagsList" :props="tagProps"
                                        :show-all-levels="false" @change="toSelectAddress" class="width360"></el-cascader>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="creditCode" label="统一社会信用代码">
                                    <el-input v-model="formData.creditCode" class="width360"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="行政区域" prop="displayLocation">
                                    <el-cascader clearable v-model="formData.displayLocation" :options="addressDataNew"
                                        ref="cascader" :props="addressProps" @change="toSelectAddress"
                                        class="width360"></el-cascader>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="email" label="联系邮箱">
                                    <el-input v-model="formData.email" class="width360"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="pageType === 'insert'">
                                <el-form-item prop="name" label="法人认证" required>
                                    <el-radio v-model="formData.appOrLegal" :label="1"
                                        @change="toSelectApporLegal">是</el-radio>
                                    <el-radio v-model="formData.appOrLegal" :label="0"
                                        @change="toSelectApporLegal">否</el-radio>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="pageType === 'insert' && !selectCompany.name">
                                <el-form-item label="营业执照" prop="licenseUrl">
                                    <div class="ml20 flex-row-start">
                                        <div v-if="formData.licenseUrl" class="upload-box">
                                            <el-image class="idcard-image" :preview-src-list="[formData.licenseUrl]"
                                                :src="formData.licenseUrl" fit="cover" ref="uploadImageView"></el-image>
                                            <div class="a-image-mask">
                                                <template>
                                                    <span class="a-image-mask-icon" @click="handleView"><i
                                                            class="el-icon-view"></i></span>
                                                    <span class="a-image-mask-icon" @click="handleDelete"><i
                                                            class="el-icon-delete"></i></span>
                                                </template>
                                            </div>
                                        </div>
                                        <el-upload accept=".png,.jpg,.jpeg" :limit="1" action="#"
                                            :http-request="toUploadFile" v-else :headers="headers"
                                            :before-upload="onBeforeUpload" :show-file-list="false">
                                            <div class="upload-box">
                                                <div class="icon">
                                                    <i class="el-icon-plus"></i>
                                                </div>
                                                <span class="upload-tips"> 营业执照</span>
                                            </div>
                                        </el-upload>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="title-bar text-left">填写法人信息</div>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item prop="operName" label="法人姓名">
                                    <el-input v-model="formData.operName" class="width360"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="legalPersonNumber" label="法人电话">
                                    <el-input v-model="formData.legalPersonNumber" class="width360"
                                        maxlength="11"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="identity" label="法人身份证号码">
                                    <el-input v-model="formData.identity" class="width360" :maxlength="18"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item prop="msgCode" label="验证码" v-if="formData.appOrLegal === 1">
                                    <el-input v-model="formData.msgCode" style="width:200px" :maxlength="6"></el-input>
                                    <el-button type="primary" @click="toSendCode" :disabled="!canSendCode" size="small"
                                        class="send-btn"
                                        :style="{ background: canSendCode ? '' : '#E2E5EC', 'border-color': canSendCode ? '' : '#E2E5EC' }">
                                        {{ countDown === 60 ? "发送验证码" : countDown + 's重新发送' }}
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <template v-if="!formData.appOrLegal">
                            <div class="title-bar text-left">填写申请人信息</div>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item prop="sponsorName" label="申请人姓名">
                                        <el-input v-model="formData.sponsorName" class="width360"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="sponsorNumber" label="申请人联系电话">
                                        <el-input v-model="formData.sponsorNumber" class="width360"
                                            maxlength="11"></el-input>
                                    </el-form-item>
                                </el-col><el-col :span="12">
                                    <el-form-item prop="sponsorIdentity" label="身份证号">
                                        <el-input v-model="formData.sponsorIdentity" class="width360"
                                            :maxlength="18"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="msgCode" label="验证码">
                                        <el-input v-model="formData.msgCode" style="width:200px" :maxlength="6"></el-input>
                                        <el-button type="primary" @click="toSendCode" :disabled="!canSendCode" size="small"
                                            class="send-btn"
                                            :style="{ background: canSendCode ? '' : '#E2E5EC', 'border-color': canSendCode ? '' : '#E2E5EC' }">
                                            {{ countDown === 60 ? "发送验证码" : countDown + 's重新发送' }}
                                        </el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </el-form>
                </div>
                <div style="padding:60px 0 80px 0">
                    <el-button class="submit-btn" @click="toSubmit" v-loading="loadingStatus">提交企业认证信息</el-button>

                    <div style="font-size:14px" class="color8">
                        <span> 提交即代表同意</span>
                        <a href="https://api.yanxin7.cn/api/user/SysTerms/get?type=yonghuxieyi" target="_blank"
                            style="color:#007EF2;text-decoration:none">《用户协议》</a>
                        <span>和</span>
                        <a href="https://api.yanxin7.cn/api/user/SysTerms/get?type=yinsizhengce" target="_blank"
                            style="color:#007EF2;text-decoration:none">《隐私政策》</a>
                    </div>

                </div>
            </template>

            <template v-else>
                <div v-loading="loading">
                    <company-detail :enterpriseDetail="enterpriseDetail"
                        @toEditEnterprise="toEditEnterprise"></company-detail>
                </div>
            </template>
        </div>
    </div>
</template>
<script>

import { getSettleCompanyList, getCompanyTags, getAddressByCode, applyEnterCompany, getEnterpriseDetail, ocrBusinessLicenseBaidu, checkIsClaim } from "@api/enterCompany"
import Setting from "@src/setting.js"
import uploadToOss from "@/utils/uploadToOss"
import { mapState } from "vuex"
import { isPhone, isIdCard } from "@/utils/validate"
import { NEWsendVerifyCode, checkVerifyCode, } from "@api/common";
import companyDetail from "./company-detail/company-detail.vue"
export default {
    components: {
        companyDetail
    },
    data() {
        var validatePhone = function (rule, value, callback) {
            if (!value) {
                callback()
            }
            if (!isPhone(value)) {
                callback(new Error('请输入正确的电话号码'));
            }
            callback();
        }
        var validateIdCard = function (rule, value, callback) {
            if (!value) {
                callback()
            }
            if (!isIdCard(value)) {
                callback(new Error('请输入正确的身份证号'));
            }
            callback();
        }
        return {
            formData: {
                appOrLegal: 1,
                tags: "",
                displayLocation: [],
                licenseUrl: "",
                sponsorName: "",
                sponsorIdentity: "",
                sponsorNumber: "",
                msgCode: "",
                operName: "",
                legalPersonNumber: "",
                identity: "",
                email: "",
                creditCode: "",
                name: "",
            },

            enterpriseDetail: {},

            tagsList: [],//企业标签列表
            defaultRules: {
                name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                licenseUrl: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
                creditCode: [{ required: true, message: '请输入信用代码', trigger: 'blur' }],
                email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }],
                tags: [{ required: true, message: '请选择企业标签', trigger: ['blur'] }],
                displayLocation: [{ required: true, message: '请选择行政区域', trigger: ['blur'] }],
                operName: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
                identity: [{ required: true, message: '请输入法人身份证号', trigger: 'blur' }, { validator: validateIdCard, trigger: 'blur' }],
                legalPersonNumber: [{ required: true, message: '请输入法人电话', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
                sponsorName: [{ required: true, message: '请输入申请人姓名', trigger: 'blur' }],
                sponsorIdentity: [{ required: true, message: '请输入申请人身份证号', trigger: 'blur' }, { validator: validateIdCard, trigger: 'blur' }],
                sponsorNumber: [{ required: true, message: '请输入申请人电话', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
                msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
            },
            rules: {},
            loadingStatus: false,
            loading: false,//获取详情loading
            isSettle: false,//是否已入驻

            isSendCode: false,//是否发送过验证码
            canSendCode: true,//是否可以发送验证码
            timer: null,
            countDown: 60,
            pageType: "insert",

            addressProps: {
                expandTrigger: "hover",
                value: "label",
            },
            tagProps: {
                expandTrigger: "hover",
                value: "name",
                label: "name",
                emitPath: false
            },

            selectCompany: {},
            beforePage: false,
        }
    },
    computed: {
        ...mapState("account", ["token", "userInfos"]),
        ...mapState("address", ["addressDataNew"]),
        headers() {
            var token = this.token;
            var headers = Setting.apiHeaders
            headers["X-Litemall-Admin-Token"] = token
            return headers
        },

        stepActive() {
            let state = this.enterpriseDetail?.auditStatus
            if (state === 0 || state === 1 || state === 3) return 3  //审核结果
            if (state === 2) return 2 //审核中
            return 1 //待提交
        },
    },

    created() {
        document.title = "邦建通-企业认证";
        this.getCompanyTags()
        this.rules = this.$lodash.cloneDeep(this.defaultRules)
    },
    mounted() {
        const query = this.$route.query
        if (query?.beforePage) {
            this.beforePage = query.beforePage
        }

        if (query?.id) {
            this.pageType = "detail"
            this.getEnterpriseDetail(query.id)
        } else {
            this.pageType = "insert"
        }
    },
    methods: {
        // 获取企业标签
        async getCompanyTags() {
            try {
                let { data } = await getCompanyTags({
                    delete: false,
                })
                data.forEach(item => {
                    item.children = this.formatTagsList(item)
                })
                this.tagsList = data
            } catch (err) {

            }
        },

        formatTagsList(tagItem) {
            let list = tagItem?.children || []
            if (list?.length === 0) return null
            list.forEach(item => {
                item.children = this.formatTagsList(item)
            })
            return [...list]
        },


        // 获取企业详情
        async getEnterpriseDetail(id) {
            try {
                this.loading = true
                let { data } = await getEnterpriseDetail({ id })
                this.enterpriseDetail = data
            } catch (err) {

            } finally {
                this.loading = false
            }
        },

        // 点击重新编辑企业
        toEditEnterprise(detail) {
            this.pageType = "edit"
            let formData = this.$lodash.cloneDeep(detail)
            if (formData.tags) {
                let tagsList = formData.tags.split(",")
                formData.tags = tagsList[0] || ''
            }

            if (formData.province || formData.city || formData.county) {
                formData.displayLocation = [formData.province, formData.city, formData.county]
            }
            formData.appOrLegal = formData.appOrLegal ? 1 : 0
            formData.msgCode = ""
            this.formData = formData
            this.enterpriseDetail = {}
            this.toSelectApporLegal(formData.appOrLegal)
            if (this.timer) {
                this.countDown = 60
                clearInterval(this.timer)
            }
        },

        toSubmit() {
            try {
                this.$refs.form.validate(async vali => {
                    if (!vali) return
                    await this.checkIsClaim(this.formData.creditCode)
                    if (this.isSettle) {
                        this.$message.warning("该企业已入驻，请重新选择")
                        return
                    }
                    if (!this.isSendCode) {
                        this.$message.warning("请先获取验证码")
                        return
                    }
                    if (this.loadingStatus) return
                    this.loadingStatus = true
                    let flag = await this.checkVerifyCode()

                    if (!flag) {
                        //验证码校验不通过
                        this.loadingStatus = false
                        return
                    }
                    let params = {
                        ...this.formData,
                        tags: this.formData.tags,
                        settleType: "bjt",
                        appOrLegal: this.formData.appOrLegal ? true : false,
                    }

                    delete params.displayLocation
                    delete params.msgCode

                    if (this.formData.appOrLegal) {
                        delete params.sponsorIdentity
                        delete params.sponsorNumber
                        delete params.sponsorName
                    }

                    let { data } = await applyEnterCompany(params)
                    // 提交后状态变更到资料审核或结果页
                    this.enterpriseDetail = data
                    this.loadingStatus = false
                })
            } catch (err) {
                this.loadingStatus = false
                // this.$message.error(err?.msg)
                console.log("err", err)
            }
        },

        // 选择地址
        toSelectAddress() {
            let nodeInfo = this.$refs["cascader"].getCheckedNodes()?.[0] || null

            let acodeList = []
            let nameList = []

            if (nodeInfo) {
                nodeInfo.pathNodes.forEach(item => {
                    nameList.push(item.data.label)
                    acodeList.push(item.data.value)
                })
            }

            let selectedArea = {
                province: nameList[0] || "",
                provinceCode: acodeList[0] || "",
                city: nameList[1] || "",
                cityCode: acodeList[1] || "",
                county: nameList[2] || "",
                countyCode: acodeList[2] || "",
            }
            this.formData = {
                ...this.formData,
                ...selectedArea,

            }
        },

        compNameChange(e) {
            if (e !== this.selectCompany?.name) {
                this.selectCompany = {}
            }
        },

        async searchCompany(query, cb) {
            try {
                if (!query) {
                    cb([])
                    return
                }
                let { data } = await getSettleCompanyList({
                    content: query || "",
                })
                data.forEach(item => item.value = item.name)
                cb(data)
            } catch (err) {

            }
        },

        toSelectCompany(item) {
            this.isSettle = false
            let companyData = {
                creditCode: item.credit_code || item.unicode || "",
                operName: item.oper_name || "",
                name: item.name,
                address: item.address,
            }
            this.selectCompany = companyData
            this.formData = {
                ...this.formData,
                ...companyData,
            }
            if (companyData.creditCode) {
                this.getAddressByCode(companyData.creditCode)
                this.checkIsClaim(companyData.creditCode)
            }
        },
        async checkIsClaim(creditCode) {
            try {
                let { data } = await checkIsClaim({
                    creditCode
                })
                if (data?.AuditStatus) {
                    this.isSettle = true
                    this.$message.warning("该企业已入驻，请重新选择")
                }
            } catch (err) {
                // this.$message.error(err?.msg)
                console.log("校验是否入驻错误", err)
            }
        },

        async getAddressByCode(creditCode) {
            try {
                let { data } = await getAddressByCode({
                    creditCode
                })
                if (data.province && data.city && data.county) {
                    this.formData.displayLocation = [data.province, data.city, data.county]
                }
                this.formData = {
                    ...this.formData,
                    ...data
                }

                console.log("33333333", data)
            } catch (err) {
                console.log("校验是否入驻错误", err)
            }
        },

        // 切换选择认证身份
        toSelectApporLegal(e) {

            if (this.timer) {
                clearInterval(this.timer)
                this.countDown = 60
                this.canSendCode = true
            }
            this.formData.msgCode = ""
            if (this.pageType !== "edit") {
                if (!this.formData.sponsorName) {
                    this.formData.sponsorName = this.userInfos?.realName || ""
                }
                if (!this.formData.sponsorIdentity) {
                    this.formData.sponsorIdentity = this.userInfos?.idCard || ""
                }
                if (!this.formData.sponsorNumber) {
                    this.formData.sponsorNumber = this.userInfos?.loginPhoneNumber || ""
                }
            }

            let rules = this.$lodash.cloneDeep(this.defaultRules)
            if (e) {
                this.rules = rules
            } else {
                rules.identity[0].required = false
                rules.legalPersonNumber[0].required = false
                this.rules = rules
            }
        },

        // 发送验证码
        async toSendCode() {
            try {
                let phone = this.formData.appOrLegal ? this.formData.legalPersonNumber : this.formData.sponsorNumber
                if (!phone) {
                    this.$message.warning("请输入联系电话")
                    return
                }
                if (!isPhone(phone)) {
                    this.$message.warning("请输入正确的联系电话")
                    return
                }

                if (!this.canSendCode) return
                if (this.timer) {
                    this.countDown = 60
                    clearInterval(this.timer)
                }
                await NEWsendVerifyCode({
                    phone,
                    smsEnum: "PHONE_COMMON",
                })
                this.$message.success("发送短信成功");
                this.timer = setInterval(() => {
                    if (!this.isSendCode) this.isSendCode = true
                    this.countDown--
                    if (this.countDown <= 0) {
                        this.countDown = 60
                        this.canSendCode = true
                        clearInterval(this.timer)
                    } else {
                        this.canSendCode = false
                    }
                }, 1000);

            } catch (err) {
                console.log("err", err)
                // this.$message.error(err?.msg)
            }
        },

        async checkVerifyCode() {
            return new Promise(async (resolve) => {
                try {
                    let phone = this.formData.appOrLegal ? this.formData.legalPersonNumber : this.formData.sponsorNumber
                    await checkVerifyCode({
                        verifyCode: this.formData.msgCode,
                        phone,
                        verifyType: 9,
                    })
                    resolve(true)
                } catch (err) {
                    console.log("err", err)
                    // this.$message(err?.msg || "校验验证码失败")
                    resolve(false)
                }
            })
        },
        // 查看大图
        handleView() {
            this.$refs.uploadImageView.clickHandler()
        },

        handleDelete() {
            this.resetFormInfo()
        },
        // //重置营业执照读不出(或删除营业执照)
        resetFormInfo() {
            this.formData.licenseUrl = ""
            this.formData.name = ""
            this.formData.operName = ""
            this.formData.creditCode = ""
            this.formData.econKind = ""
            this.formData.capi = ""
            this.formData.registCapi = ""
            this.formData.startDate = ""
            this.formData.scope = ""
            this.formData.address = ""
            this.addrDetail = {}
        },
        // 上传营业执照
        async toUploadFile(data) {
            try {
                let res = await this.getFileUrl(data.file)
                this.formData.licenseUrl = res
                this.getLicenseData(res)
            } catch (err) {
                // this.$message.error("上传文件错误")
            }
        },

        // 识别营业执照数据
        async getLicenseData(url) {
            try {
                let { data } = await ocrBusinessLicenseBaidu({
                    url
                })
                if (JSON.stringify(data) === "{}") {
                    this.$message.error("识别营业执照错误")
                    this.resetFormInfo()
                    return
                }
                let businessLicenseInfo = data.businessLicenseInfo
                this.formData.address = businessLicenseInfo.address
                this.formData.licenseUrl = url
                this.formData.name = businessLicenseInfo.name
                this.formData.operName = businessLicenseInfo.legalPerson
                this.formData.creditCode = businessLicenseInfo.creditNo
                this.formData.econKind = businessLicenseInfo.type
                this.formData.capi = businessLicenseInfo.registeredCapital
                this.formData.registCapi = businessLicenseInfo.registeredCapital
                this.formData.startDate = businessLicenseInfo.dateOfEstablishment
                this.formData.scope = businessLicenseInfo.business
                if (businessLicenseInfo.creditNo) {
                    this.checkIsClaim(businessLicenseInfo.creditNo)
                    this.getAddressByCode(businessLicenseInfo.creditNo)
                }
            } catch (err) {
                // this.$message.error(err?.msg || "识别营业执照错误")
                this.resetFormInfo()
                console.log("识别营业执照错误", err)
            }
        },

        // 文件上传之前的回调函数
        // 文件上传之前的回调函数
        async onBeforeUpload(file) {
            var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (!/^png|jpg|jpeg$/.test(fileExtension)) {
                this.$message.warning(`仅支持格式为png、jpg、jpeg的格式上传!`)
                return Promise.reject()
            }
            const fileSize = 2 * 1024 * 1024;
            if (fileSize < file.size) {
                this.$message.warning(`文件大小不能超过2MB`)
                return Promise.reject()
            }

            return true
        },
        getFileUrl(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await uploadToOss.uploadFile(file, "image")
                    resolve(res)
                } catch (err) {
                    reject(err)
                }
            })
        },

        toBack() {
            if (this.beforePage) {
                this.$router.replace(this.beforePage)
            } else {
                this.$router.go(-1)
            }
        },
    }
}

</script>
<style>
.el-step__icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;

}

.el-step__icon.is-text {
    border: none;
}

.el-step.is-horizontal .el-step__line {
    top: 20px;
}
</style>
<style scoped lang="less">
.text-left {
    text-align: left;
}

.width360 {
    width: 360px;
}


.step-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 22px;
    color: #212121;
}

.step-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    border-radius: 100%;
    background: #D2E6FC;
    display: flex;
    align-items: center;
    justify-content: center;


}

.step-icon>div {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}


.header-title {
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #DCE5F2;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.title-bar {
    background: #F8F9FB;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.submit-btn {
    width: 400px;
    padding: 20px 0;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background: #2180EF;
    margin-bottom: 20px;
}

.send-btn {
    background: #2180EF;
    padding: 12px 0;
    margin-left: 10px;
    width: 140px;
}

.idcard-image {
    width: 200px;
    height: 126px;
    border-radius: 4px;
}

.upload-box {
    width: 200px;
    height: 126px;
    border: 1px solid #C6CBD5;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
        font-size: 30px;
        font-weight: bold;
        color: #888888;
    }

    .upload-tips {
        font-size: 16px;
        font-weight: bold;
        color: #888;
    }
}

.upload-box:hover .a-image-mask {
    display: block;
}

.a-image-mask {
    display: none;
    width: 200px;
    height: 126px;
    line-height: 126px;
    position: absolute;
    z-index: 2;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.a-image-mask>.a-image-mask-icon {
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
}
</style>


