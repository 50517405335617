import request from "@src/plugins/request";

// 获取企业列表数据
export const getSettleCompanyList = function (data) {
    return request({
        url: "/bxbh/enterprise/getEsEnterprise",
        method: "GET",
        params: data,
    });
};

// 获取企业标签选择
export const getCompanyTags = function (data) {
    return request({
        url: "/enterprise/tag/list2",
        method: "POST",
        data,
    });
};

// 根据creditCode查询地址
export const getAddressByCode = function (data) {
    return request({
        url: "/region/getAddrByCreditCode",
        method: "GET",
        params: data,
    });
};

// 校验是否已被入住
export const checkIsClaim = function (data) {
    return request({
        url: "/bxbh/enterprise/getByCode",
        method: "GET",
        params: data,
    });
};

// 校验是否已被入住
export const applyEnterCompany = function (data) {
    return request({
        url: "/bxbh/enterprise/applytwo",
        method: "POST",
        data,
    });
};

export const getEnterpriseDetail = function (data) {
    return request({
        url: "/bxbh/enterprise/getSettledEnterprise",
        method: "GET",
        params: data,
    });
};

// 识别营业执照
export const ocrBusinessLicenseBaidu = function (data) {
    return request({
        url: "/commonOcr/ocrBusinessLicenseBaidu",
        method: "GET",
        params: data,
    });
};
