<template>
    <div style="padding-bottom:100px">

        <div v-if="isApplying"
            style="margin-top:40px;padding:12px;text-align:left;font-size:14px;color:#1890FF;background:#E7F4FF;border-radius:2px">
            审核处理中，请耐心等待...
        </div>
        <div style="position:relative">
            <el-image :src="resultImage" v-if="resultImage" class="result-image"></el-image>
            <div class="title">企业信息</div>
            <el-descriptions :column="2" border>
                <el-descriptions-item label="企业名称" label-class-name="label-class" content-class-name="content-class">{{
                    model.name || "" }}</el-descriptions-item>
                <el-descriptions-item label="企业标签" label-class-name="label-class" content-class-name="content-class"> {{
                    model.displayTags
                }}</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码" label-class-name="label-class" content-class-name="content-class">{{
                    model.creditCode
                }}</el-descriptions-item>
                <el-descriptions-item label="行政区域" label-class-name="label-class" content-class-name="content-class"> {{
                    model.displayLocation
                }}</el-descriptions-item>
                <el-descriptions-item label="联系邮箱" label-class-name="label-class" content-class-name="content-class">
                    {{ model.email || "-" }}
                </el-descriptions-item>
                <el-descriptions-item label="营业执照" label-class-name="label-class" content-class-name="content-class"
                    v-if="model.licenseUrl">
                    <el-image class="idcard-image" :preview-src-list="[model.licenseUrl]" :src="model.licenseUrl"
                        fit="cover"></el-image>
                </el-descriptions-item>
            </el-descriptions>
        </div>

        <div class="title">法人信息</div>
        <el-descriptions :column="2" border>
            <el-descriptions-item label="法人姓名" label-class-name="label-class" content-class-name="content-class">
                {{ model.operName || "" }}
            </el-descriptions-item>
            <el-descriptions-item label="法人联系电话" label-class-name="label-class" content-class-name="content-class">
                {{ model.legalPersonNumber || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label="法人身份证号码" label-class-name="label-class" content-class-name="content-class">
                {{ model.identity || "-" }}
            </el-descriptions-item>
        </el-descriptions>
        <template v-if="!model.appOrLegal">
            <div class="title">申请人信息</div>
            <el-descriptions :column="2" border>
                <el-descriptions-item label="申请人姓名" label-class-name="label-class" content-class-name="content-class">
                    {{ model.sponsorName || "" }}
                </el-descriptions-item>
                <el-descriptions-item label="申请人联系电话" label-class-name="label-class" content-class-name="content-class">
                    {{ model.sponsorNumber || "-" }}
                </el-descriptions-item>
                <el-descriptions-item label="申请人身份证号码" label-class-name="label-class" content-class-name="content-class">
                    {{ model.sponsorIdentity || "-" }}
                </el-descriptions-item>
            </el-descriptions>
        </template>

        <div v-if="model.auditStatus === 0" style="margin-top:30px;text-align:left;">
            <el-button style="width:140px;padding:12px 0;border-color:#1890FF;color:#1890FF;"
                @click="toEditEnterprise">重新认证企业</el-button>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        enterpriseDetail: {
            default() {
                return {}
            },
            type: Object,
        }
    },

    computed: {
        model() {
            let model = {
                ...this.enterpriseDetail
            }

            if (model.province || model.city || model.county) {
                model.displayLocation = `${model.province ? model.province + "-" : ""}${model.city ? model.city + "-" : ""}${model.county || ""}`
            } else {
                model.displayLocation = "-"
            }
            model.displayTags = model.tags ? model.tags.replaceAll("/", ",") : "-"

            return model
        },

        isApplying() {
            return this.model?.auditStatus === 2
        },
        resultImage() {
            let state = this.model?.auditStatus
            if (state === 0) return "https://oss.yanxin7.cn/2023-08-25/84a04f450a28f6bb01d9854c05aeb7e5"
            if (state === 1 || state === 3) return "https://oss.yanxin7.cn/2023-08-25/899ab64a6c2d859366ee36f30f9cb2f5"
            return ""
        }
    },
    methods: {
        toEditEnterprise() {
            this.$emit("toEditEnterprise", this.enterpriseDetail)
        }
    }
}
</script>

<style >
.label-class {
    width: 200px;
    text-align: right;
    padding-right: 40px;
}

.content-class {
    width: 560px
}
</style>

<style scoped>
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    margin-top: 44px;
}

.idcard-image {
    width: 200px;
    height: 126px;
    border-radius: 4px;
}

.result-image {
    position: absolute;
    right: 0;
    width: 240px;
    height: 240px;
    z-index: 9;
}
</style>